<nz-spin [nzSpinning]="isSpinning">
    <div class="pt-3">
        <nz-table [nzData]="dataItem" [nzShowPagination]="false" [nzScroll]="{y:'300px', x: '500px'}">
            <thead>
                <tr>
                    <th nzWidth="80px">
                        STT
                    </th>
                    <th>
                        Tên người xử lý
                    </th>
                    <th nzWidth="150px">Ngày xử lý</th>
                    <th>Lý do</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataItem; index as idx">
                    <td class="text-center">
                        {{idx + 1}}
                    </td>
                    <td>{{ item.tenKeToan }}</td>
                    <td>{{ item.creationTime | luxonFormat : 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{ item.lyDoHuyThanhToan }}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>

    <div *nzModalFooter>
        <button type="button" nz-button nzType="default" class="btn-default" (click)="close()">
            <span nz-icon nzType="close" nzTheme="outline"></span>{{ 'Close' | localize }}
        </button>
    </div>
</nz-spin>
