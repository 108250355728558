import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { KeToanXuLyGiayBaoThuServiceProxy, LyDoTuChoiGiayBaoThu } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'view-ly-do-tu-choi-giay-bao-thu',
    templateUrl: './view-ly-do-tu-choi-giay-bao-thu.component.html',
    styleUrls: ['./view-ly-do-tu-choi-giay-bao-thu.component.scss']
})
export class ViewLyDoTuChoiGiayBaoThuComponent extends ModalComponentBase implements OnInit {
    @Input() yeuCauId = null;
    dataItem: LyDoTuChoiGiayBaoThu[] = [];
    constructor(
        injector: Injector,
        private _dataService: KeToanXuLyGiayBaoThuServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.yeuCauId) {
            this.getLyDoTuChoi(this.yeuCauId);
        }
    }

    getLyDoTuChoi(yeuCauId) {
        this.isSpinning = true;
        this._dataService.getLyDoTuChoiGiayBaoThuById(yeuCauId)
            .pipe(finalize(() => { this.isSpinning = false; }))
            .subscribe(res => {
                if (res.isSuccessful) {
                    this.dataItem = res.dataResult;
                    console.log(this.dataItem);
                } else {
                    abp.notify.error(res.errorMessage);
                }
            })
    }
}
