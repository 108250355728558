export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export enum TYPE_VALIDATE {
    Number = 1,
    Email = 2,
    Moment = 3,
    MinValue = 4,
    MaxValue = 5,
    PassWord = 6,
    Phone = 7,
    CheckDuplicates = 8,
    Dynamic = 9,
    Datetime = 10,
    CCCD = 11,
    MaBHYT = 12,
    ValidRef = 13,
    Array = 14,
}

export enum TYPE_DYNAMIC_COMBO {
    SELECT = 1,
    RADIO = 2,
  }
