import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.PhongBanServiceProxy,
        ApiServiceProxies.ComboBaseServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.SinhMaHoSoServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.TaiKhoanDoanhNghiepServiceProxy,

        ApiServiceProxies.ChucVuServiceProxy,
        ApiServiceProxies.ThietLapDomainServiceProxy,
        ApiServiceProxies.NgayNghiServiceProxy,
        ApiServiceProxies.LichLamViecServiceProxy,
        ApiServiceProxies.UtilityServiceProxy,
        ApiServiceProxies.FileUploadServiceProxy,
        ApiServiceProxies.DoanhNghiepServiceProxy,
        ApiServiceProxies.DoanhNghiepDangKyServiceProxy,
        ApiServiceProxies.DanhMucLoaiSanPhamServiceProxy,
        ApiServiceProxies.DanhMucDangTrinhBayServiceProxy,
        //Dashboard
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.TrangChuDieuHuongServiceProxy,

        //Thống kê báo cáo
        ApiServiceProxies.BaoCaoChoKeToanServiceProxy,

        //quản lý hồ sơ
        ApiServiceProxies.QuanLyHoSoMotCuaServiceProxy,
        ApiServiceProxies.QuanLyHoSoKeToanServiceProxy,
        ApiServiceProxies.QuanLyHoSoVanThuServiceProxy,
        ApiServiceProxies.QuanLyKeToanGiayBaoThuServiceProxy,
        ApiServiceProxies.KeToanXuLyGiayBaoThuServiceProxy,
        //doanh nghiệp thanh toán
        ApiServiceProxies.DoanhNghiepThanhToanServiceProxy,
        //quản lý thủ tục
        ApiServiceProxies.DanhMucThuTucServiceProxy,
        ApiServiceProxies.LoaiHoSoThuTucServiceProxy,
        //thủ tục 04
        ApiServiceProxies.XuLyHoSoCommonView04ServiceProxy,
        ApiServiceProxies.XuLyHoSoGridView04ServiceProxy,
        ApiServiceProxies.XuLyHoSoDoanhNghiep04ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhanCong04ServiceProxy,
        ApiServiceProxies.XuLyHoSoThamDinh04ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhoPhong04ServiceProxy,
        ApiServiceProxies.XuLyHoSoTruongPhong04ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoCuc04ServiceProxy,
        ApiServiceProxies.XuLyHoSoVanThu04ServiceProxy,
        ApiServiceProxies.ThuTuc04ServiceProxy,
        ApiServiceProxies.XuLyHoSoMotCuaTiepNhan04ServiceProxy,
        ApiServiceProxies.XuLyHoSoKeToan04ServiceProxy,

        //thủ tục 05
        ApiServiceProxies.XuLyHoSoCommonView05ServiceProxy,
        ApiServiceProxies.XuLyHoSoGridView05ServiceProxy,
        ApiServiceProxies.XuLyHoSoDoanhNghiep05ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhanCong05ServiceProxy,
        ApiServiceProxies.XuLyHoSoThamDinh05ServiceProxy,
        ApiServiceProxies.XuLyHoSoTruongPhong05ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhoPhong05ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoCuc05ServiceProxy,
        ApiServiceProxies.XuLyHoSoVanThu05ServiceProxy,
        ApiServiceProxies.ThuTuc05ServiceProxy,
        ApiServiceProxies.XuLyHoSoMotCuaTiepNhan05ServiceProxy,
        ApiServiceProxies.XuLyHoSoKeToan05ServiceProxy,

        //thủ tục 06
        ApiServiceProxies.XuLyHoSoCommonView06ServiceProxy,
        ApiServiceProxies.XuLyHoSoGridView06ServiceProxy,
        ApiServiceProxies.XuLyHoSoDoanhNghiep06ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhanCong06ServiceProxy,
        ApiServiceProxies.XuLyHoSoThamDinh06ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoPhong06ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoCuc06ServiceProxy,
        ApiServiceProxies.XuLyHoSoVanThu06ServiceProxy,
        ApiServiceProxies.ThuTuc06ServiceProxy,
        ApiServiceProxies.XuLyHoSoMotCuaTiepNhan06ServiceProxy,
        ApiServiceProxies.XuLyHoSoKeToan06ServiceProxy,

        //thủ tục 07
        ApiServiceProxies.XuLyHoSoCommonView07ServiceProxy,
        ApiServiceProxies.XuLyHoSoGridView07ServiceProxy,
        ApiServiceProxies.XuLyHoSoDoanhNghiep07ServiceProxy,
        ApiServiceProxies.XuLyHoSoPhanCong07ServiceProxy,
        ApiServiceProxies.XuLyHoSoThamDinh07ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoPhong07ServiceProxy,
        ApiServiceProxies.XuLyHoSoLanhDaoCuc07ServiceProxy,
        ApiServiceProxies.XuLyHoSoVanThu07ServiceProxy,
        ApiServiceProxies.ThuTuc07ServiceProxy,
        ApiServiceProxies.XuLyHoSoMotCuaTiepNhan07ServiceProxy,
        ApiServiceProxies.XuLyHoSoKeToan07ServiceProxy,

        //kho dược
        ApiServiceProxies.KhoDuocMyPhamServiceProxy,
        //sở y tế
        ApiServiceProxies.SoYTeServiceProxy,

        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule { }
