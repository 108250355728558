import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [

            new AppMenuItem('Trang chủ', 'Pages.ThuTucHanhChinh', 'home', '/app/trang-chu/xu-ly-chung'),

            //DOANH NGHIỆP
            new AppMenuItem('Thông tin doanh nghiệp', 'Pages.DoanhNghiep.ThongTinDoanhNghiep', 'snippets', '/app/quan-ly-doanh-nghiep/thong-tin-doanh-nghiep'),

            new AppMenuItem('Quản lý doanh nghiệp', 'Pages.QuanLyDoanhNghiep', 'snippets', '', [
                new AppMenuItem('Duyệt tài khoản doanh nghiệp', 'Pages.QuanLyDoanhNghiep.DoanhNghiepDangKy', 'team', '/app/quan-ly-doanh-nghiep/doanh-nghiep-dang-ky'),
                new AppMenuItem('Danh sách doanh nghiệp', 'Pages.QuanLyDoanhNghiep.DanhSachDoanhNghiep', 'team', '/app/quan-ly-doanh-nghiep/danh-sach-doanh-nghiep'),
            ]),

           

            new AppMenuItem('Quản lý danh mục thủ tục', 'Pages.QuanLyDanhMucThuTuc', 'snippets', '', [

            ]),

            new AppMenuItem('Xác nhận nộp phí', 'Pages.ThuTucHanhChinh.DanhSachHoSoKeToan', 'bars', '/app/thu-tuc-hanh-chinh/quan-ly-ho-so/danh-sach-giay-bao-thu'),

            new AppMenuItem('Hồ sơ cấp phép', 'Pages.ThuTucHanhChinh', 'snippets', '', [
                new AppMenuItem('Danh sách hồ sơ một cửa', 'Pages.ThuTucHanhChinh.DanhSachHoSoMotCua', 'bars', '/app/thu-tuc-hanh-chinh/quan-ly-ho-so/danh-sach-ho-so-mot-cua'),
                //new AppMenuItem('Danh sách hồ sơ kế toán', 'Pages.ThuTucHanhChinh.DanhSachHoSoKeToan', 'bars', '/app/thu-tuc-hanh-chinh/quan-ly-ho-so/danh-sach-ho-so-ke-toan'),
                
                new AppMenuItem('Danh sách hồ sơ văn thư', 'Pages.ThuTucHanhChinh.DanhSachHoSoVanThu', 'bars', '/app/thu-tuc-hanh-chinh/quan-ly-ho-so/danh-sach-ho-so-van-thu'),
                new AppMenuItem('Thủ tục 04', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/lua-chon-chuc-nang'),
                new AppMenuItem('Thủ tục 05', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/lua-chon-chuc-nang'),
                new AppMenuItem('Thủ tục 06', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/lua-chon-chuc-nang'),
                new AppMenuItem('Thủ tục 07', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/lua-chon-chuc-nang'),
            ]),

            new AppMenuItem('Nộp phí hồ sơ', 'Pages.DoanhNghiepThanhToan', 'dollar', '/app/doanh-nghiep-thanh-toan/danh-sach-ho-so-thanh-toan'),

            new AppMenuItem('Sở Y tế', 'Pages.SoYte', 'medicine-box', '', [
            ]),

            new AppMenuItem('Pages_ThongKeBaoCao', 'Pages.ThongKeBaoCao', 'snippets', '', [
                new AppMenuItem('Pages_ThongKeBaoCao_KeToan', 'Pages.ThongKeBaoCao.KeToan', 'team', '/app/thong-ke-bao-cao/bao-cao-ke-toan'),
            ]),

            new AppMenuItem('Danh mục hành chính', 'Pages.DanhMuc', 'bars', '', [
                new AppMenuItem('Địa chính', 'Pages.DanhMuc.DiaChinh', 'environment', '', [
                    new AppMenuItem('Quốc gia', 'Pages.DanhMuc.QuocTich', 'environment', '/app/danh-muc/quoc-gia'),
                    new AppMenuItem('Tỉnh / thành phố', 'Pages.DanhMuc.Tinh', 'environment', '/app/danh-muc/tinh'),
                    new AppMenuItem('Quận / huyện', 'Pages.DanhMuc.Huyen', 'environment', '/app/danh-muc/huyen'),
                    new AppMenuItem('Phường / xã', 'Pages.DanhMuc.Xa', 'environment', '/app/danh-muc/xa'),
                ]),
                new AppMenuItem('Lịch làm việc', 'Pages.DanhMuc.LichLamViec', 'calendar', '/app/danh-muc/lich-lam-viec'),
                //new AppMenuItem('Cây danh mục', 'Pages.DanhMuc.CayDanhMuc', 'check-circle', '/app/danh-muc/danh-muc-chung'),
                new AppMenuItem('Phòng ban', 'Pages.DanhMuc.PhongBan', 'block', '/app/danh-muc/phong-ban'),
                //new AppMenuItem('Chức vụ', 'Pages.DanhMuc.ChucVu', 'team', '/app/danh-muc/chuc-vu'),
                new AppMenuItem('Danh mục thủ tục', 'Pages.DanhMuc.DanhMucThuTuc', 'bars', '/app/danh-muc/quan-ly-danh-muc-thu-tuc/danh-muc-thu-tuc'),
                new AppMenuItem('Danh mục loại hồ sơ', 'Pages.DanhMuc.LoaiHoSoThuTuc', 'bars', '/app/danh-muc/quan-ly-danh-muc-thu-tuc/loai-ho-so-thu-tuc'),
                new AppMenuItem('Danh mục Sở Y tế', 'Pages.DanhMuc.DanhMucSoYTe', 'bars', '/app/danh-muc/danh-muc-so-y-te'),
                new AppMenuItem('Danh mục loại sản phẩm', 'Pages.DanhMuc.LoaiSanPham', 'bars', '/app/danh-muc/loai-san-pham'),
                new AppMenuItem('Danh mục dạng trình bày', 'Pages.DanhMuc.DangTrinhBay', 'bars', '/app/danh-muc/dang-trinh-bay'),
            ]),


            new AppMenuItem('Thiết lập', 'Pages.HeThong', 'tool', '', [
                //   new AppMenuItem('Cài đặt', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
                new AppMenuItem('Tài khoản cán bộ', 'Pages.HeThong.QuanLyTaiKhoan', 'user', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Tài khoản doanh nghiệp', 'Pages.HeThong.QuanLyTaiKhoan', 'user', '/app/he-thong/tai-khoan-doanh-nghiep'),
                new AppMenuItem('Thiết lập chữ ký số', 'Pages.HeThong.ThietLapChuKySo', 'setting', '/app/he-thong/thiet-lap-chu-ky-so'),
                //new AppMenuItem('Thiết lập chung', 'Pages.HeThong.ThietLapChung', 'setting', '/app/he-thong/thiet-lap-chung'),
                //new AppMenuItem('Thiết lập domain', 'Pages.HeThong.DomainRoute', 'setting', '/app/he-thong/thiet-lap-domain'),
                new AppMenuItem('Tài liệu hướng dẫn sử dụng', 'Pages.HeThong.TaiLieuHuongDan', 'book', '/app/he-thong/tai-lieu-huong-dan'),

            ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                //new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('Nhật ký hệ thống', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings')
            ]),



            // new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),
            // new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HuongDanSuDung', 'check-circle', '/app/he-thong/xem-huong-dan-su-dung'),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
