import { Component, Injector, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalComponentBase } from '@shared/common/modal-component-base';

@Component({
    selector: 'view-pdf',
    templateUrl: './view-pdf.component.html',
    styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfKetQuaComponent implements OnInit {
    @Input() path;
    constructor(injector: Injector, protected sanitizer: DomSanitizer) {

    }

    ngOnInit(): void {
        console.log(this.path, "path");
    }

    getPath() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.path)
    }
}
