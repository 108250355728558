import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: 'welcome', component: WelcomeComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then(m => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'trang-chu',
                loadChildren: () => import('./pages/trang-chu/trang-chu.module').then(m => m.TrangChuModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then(m => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then(m => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },

            {
                path: 'danh-muc-chuyen-mon',
                loadChildren: () => import('./pages/danh-muc-chuyen-mon/danh-muc-chuyen-mon.module').then(m => m.DanhMucChuyenMonModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-04',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/thu-tuc-04/thu-tuc-04.module').then(m => m.ThuTuc04Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-05',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/thu-tuc-05/thu-tuc.module').then(m => m.ThuTuc05Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-06',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/thu-tuc-06/thu-tuc.module').then(m => m.ThuTuc06Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-07',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/thu-tuc-07/thu-tuc.module').then(m => m.ThuTuc07Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-doanh-nghiep',
                loadChildren: () => import('./pages/quan-ly-doanh-nghiep/quan-ly-doanh-nghiep.module').then(m => m.QuanLyDoanhNghiepModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'doanh-nghiep-thanh-toan',
                loadChildren: () => import('./pages/doanh-nghiep-thanh-toan/doanh-nghiep-thanh-toan.module').then(m => m.DoanhNghiepThanhToanModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thong-ke-bao-cao',
                loadChildren: () => import('./pages/thong-ke-bao-cao/quan-ly-bao-cao.module').then(m => m.QuanLyBaoCaoModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/quan-ly-ho-so',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/quan-ly-ho-so/quan-ly-ho-so.module').then(m => m.QuanLyHoSoModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc/quan-ly-danh-muc-thu-tuc',
                loadChildren: () => import('./pages/quan-ly-thu-tuc/quan-ly-thu-tuc.module').then(m => m.QuanLyThuTucModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },

            { path: '**', redirectTo: '/app/main/dashboard' },
        ],
    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ]
    ,
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private router: Router,
    ) {

    }
}
