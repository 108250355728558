<div class="alain-default__progress-bar" *ngIf="isFetching"></div>
<layout-header class="alain-default__header" *ngIf="!installationMode" (toggleMenu)="layoutSidebar.toggleCollapsed()"></layout-header>
<!-- <layout-navbar *ngIf="!installationMode"></layout-navbar> -->
<!-- <layout-sidebar class="alain-default__aside" #layoutSidebar *ngIf="!installationMode"></layout-sidebar> -->
<layout-sidebar class="alain-default__aside" #layoutSidebar></layout-sidebar>
<section [className]="installationMode ? 'alain-fullscreen' : 'alain-default__content'">
<section class="alain-fullscreen" style="margin-top: 130px">
    <router-outlet></router-outlet>
    <!-- <div class="nav-reutab-custom" style="padding-left: 15px;">

        <reuse-tab [mode]="2" #reuseTab></reuse-tab>
    </div> -->
    

    <!-- <reuse-tab [mode]="2" (close)="tabClose($event)" (change)="tabChange($event)" #reuseTab></reuse-tab>
    <router-outlet (activate)="reuseTab.activate($event)"></router-outlet> -->

</section>
<ng-template #settingHost></ng-template>
<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>

<!-- <div class="nav-reutab-custom" style="padding-left: 15px;">

    <reuse-tab [mode]="2" #reuseTab></reuse-tab>
    <router-outlet (activate)="reuseTab.activate($event)"></router-outlet>
</div> -->
<!--<layout-theme-btn></layout-theme-btn>-->
