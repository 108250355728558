<h3 class="modal-header">
    <span>{{'ChangePassword' | localize}}</span>
</h3>
<form nz-form #changePasswordModalForm="ngForm" (ngSubmit)="save()">
    <nz-form-item style="padding-bottom: 10px;">
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="CurrentPassword">{{'CurrentPassword' | localize}}
        </nz-form-label>
        <nz-form-control style="padding-left: 10px;" [nzErrorTip]="currentPasswordValidationTpl" [nzSm]="14" [nzXs]="24"
            nzHasFeedback>
            <input nz-input name="CurrentPassword" #currentPasswordInput="ngModel" [(ngModel)]="currentPassword"
                placeholder="{{'CurrentPassword' | localize}}" type="password" required>
            <ng-template #currentPasswordValidationTpl let-control>
                <validation-messages [formCtrl]="control"></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item style="padding-bottom: 10px;">
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="NewPassword">{{'NewPassword' | localize}}
        </nz-form-label>
        <nz-form-control style="padding-left: 10px;" [nzErrorTip]="newPasswordValidationTpl" [nzSm]="14" [nzXs]="24"
            nzHasFeedback>
            <input nz-input name="NewPassword" #NewPasswordInput="ngModel" [(ngModel)]="password"
                placeholder="{{'NewPassword' | localize}}" type="password" validateEqual="NewPasswordRepeat"
                reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit"
                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                [requireUppercase]="passwordComplexitySetting.requireUppercase"
                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                [requiredLength]="passwordComplexitySetting.requiredLength" required>
            <ng-template #newPasswordValidationTpl let-control>
                <ul *ngIf="control.errors">
                    <li [hidden]="!control.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}
                    </li>
                    <li [hidden]="!control.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" |
                        localize}}
                    </li>
                    <li [hidden]="!control.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" |
                        localize}}
                    </li>
                    <li [hidden]="!control.errors.requireNonAlphanumeric">
                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                    <li [hidden]="!control.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" |
                        localize:passwordComplexitySetting.requiredLength}}
                    </li>
                </ul>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item style="padding-bottom: 10px;">
        <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="NewPasswordRepeat">{{'NewPasswordRepeat' | localize}}
        </nz-form-label>
        <nz-form-control style="padding-left: 10px;" [nzErrorTip]="newPasswordRepeatValidationTpl" [nzSm]="14"
            [nzXs]="24" nzHasFeedback>
            <input nz-input name="NewPasswordRepeat" #NewPasswordRepeatInput="ngModel" [(ngModel)]="confirmPassword"
                placeholder="{{'NewPasswordRepeat' | localize}}" type="password" validateEqual="NewPassword"
                [requireDigit]="passwordComplexitySetting.requireDigit"
                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                [requireUppercase]="passwordComplexitySetting.requireUppercase"
                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                [requiredLength]="passwordComplexitySetting.requiredLength" reverse="false" required>
            <ng-template #newPasswordRepeatValidationTpl let-control>
                <ul *ngIf="control.errors">
                    <li [hidden]="!control.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}
                    </li>
                    <li [hidden]="!control.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" |
                        localize}}
                    </li>
                    <li [hidden]="!control.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" |
                        localize}}
                    </li>
                    <li [hidden]="!control.errors.requireNonAlphanumeric">
                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                    <li [hidden]="!control.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" |
                        localize:passwordComplexitySetting.requiredLength}}
                    </li>
                    <li [hidden]="control.valid">{{"PasswordsDontMatch" | localize}}</li>
                </ul>
            </ng-template>
        </nz-form-control>
    </nz-form-item>

    <div class="modal-footer">
        <button nz-button type="button" [disabled]="saving" (click)="close()">
            {{"Cancel" | localize}}
        </button>
        <button nz-button [nzType]="'primary'" type="submit" [disabled]="!changePasswordModalForm.form.valid || saving"
            [nzLoading]="saving">
            <i nz-icon nzType="save"></i> {{"Save" | localize}}
        </button>
    </div>
</form>