import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppHccMenu, AppMenu } from './app-menu';
import { AppHccMenuItem, AppMenuItem } from './app-menu-item';
import { AppConsts } from '@shared/AppConsts';
import { TT04_FORM_CASE_CHUYEN_VIEN_THAM_DINH, TT04_FORM_CASE_DANG_KY_HO_SO, TT04_FORM_CASE_LANH_DAO_CUC_DUYET, TT04_FORM_CASE_LANH_DAO_PHONG, TT04_FORM_CASE_PHONG_BAN_PHAN_CONG, TT04_FORM_CASE_PHO_PHONG, TT04_FORM_ID, TT05_FORM_CASE_CHUYEN_VIEN_THAM_DINH, TT05_FORM_CASE_DANG_KY_HO_SO, TT05_FORM_CASE_LANH_DAO_CUC_DUYET, TT05_FORM_CASE_PHONG_BAN_PHAN_CONG, TT05_FORM_CASE_PHO_PHONG, TT05_FORM_CASE_TRUONG_PHONG, TT05_FORM_ID, TT06_FORM_CASE_CHUYEN_VIEN_THAM_DINH, TT06_FORM_CASE_DANG_KY_HO_SO, TT06_FORM_CASE_LANH_DAO_CUC_DUYET, TT06_FORM_CASE_LANH_DAO_PHONG, TT06_FORM_CASE_PHONG_BAN_PHAN_CONG, TT06_FORM_ID, TT07_FORM_CASE_CHUYEN_VIEN_THAM_DINH, TT07_FORM_CASE_DANG_KY_HO_SO, TT07_FORM_CASE_LANH_DAO_CUC_DUYET, TT07_FORM_CASE_LANH_DAO_PHONG, TT07_FORM_CASE_PHONG_BAN_PHAN_CONG, TT07_FORM_ID } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppHccNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(

    ) { }

    getMenu(): AppHccMenu {
        return new AppHccMenu('MainMenu', 'MainMenu', [
            new AppHccMenuItem('Hồ sơ cấp phép', 'Pages.ThuTucHanhChinh.HoSoThuTuc', 'snippets', '', null, [], [
                new AppHccMenuItem('Thủ tục 04', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/lua-chon-chuc-nang', null, [],
                    [
                        new AppHccMenuItem('Đăng ký hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.NopHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-doanh-nghiep',
                            TT04_FORM_ID.DOANH_NGHIEP, [
                            TT04_FORM_CASE_DANG_KY_HO_SO.HO_SO_MOI,
                            TT04_FORM_CASE_DANG_KY_HO_SO.HO_SO_CHO_THANH_TOAN,
                            TT04_FORM_CASE_DANG_KY_HO_SO.HO_SO_CAN_BO_SUNG,
                            TT04_FORM_CASE_DANG_KY_HO_SO.HO_SO_BI_TRA_LAI,
                        ]),
                        new AppHccMenuItem('Phân công hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.PhanCongHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-phan-cong',
                            TT04_FORM_ID.PHAN_CONG, [
                            TT04_FORM_CASE_PHONG_BAN_PHAN_CONG.HO_SO_MOI_CHO_PHAN_CONG,
                            TT04_FORM_CASE_PHONG_BAN_PHAN_CONG.HO_SO_BO_SUNG_CHO_PHAN_CONG
                        ]),
                        new AppHccMenuItem('Rà soát', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.ThamDinhHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-tham-dinh',
                            TT04_FORM_ID.CHUYEN_VIEN, [TT04_FORM_CASE_CHUYEN_VIEN_THAM_DINH.CHO_THAM_DINH,
                            TT04_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_LAI,
                            TT04_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_BO_SUNG]),
                        new AppHccMenuItem('Phó phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.LanhDaoPhongDuyetHoSo.PhoPhong', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-pho-phong-duyet',
                            TT04_FORM_ID.PHO_PHONG, [TT04_FORM_CASE_PHO_PHONG.CHO_DUYET,
                            TT04_FORM_CASE_PHO_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Trưởng phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.LanhDaoPhongDuyetHoSo.TruongPhong', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-truong-phong-duyet',
                            TT04_FORM_ID.TRUONG_PHONG, [TT04_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET,
                            TT04_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Lãnh đạo cục duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT04.LanhDaoCucDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-04/ho-so-lanh-dao-duyet',
                            TT04_FORM_ID.LANH_DAO_CUC, [TT04_FORM_CASE_LANH_DAO_CUC_DUYET.CHO_DUYET]),
                    ]),
                new AppHccMenuItem('Thủ tục 05', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/lua-chon-chuc-nang', null, [],
                    [
                        new AppHccMenuItem('Đăng ký hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.NopHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-doanh-nghiep',
                            TT05_FORM_ID.DOANH_NGHIEP, [
                            TT05_FORM_CASE_DANG_KY_HO_SO.HO_SO_MOI,
                            TT05_FORM_CASE_DANG_KY_HO_SO.HO_SO_CHO_THANH_TOAN,
                            TT05_FORM_CASE_DANG_KY_HO_SO.HO_SO_CAN_BO_SUNG,
                            TT05_FORM_CASE_DANG_KY_HO_SO.HO_SO_BI_TRA_LAI,
                        ]),
                        new AppHccMenuItem('Phân công hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.PhanCongHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-phan-cong',
                            TT05_FORM_ID.PHAN_CONG, [TT05_FORM_CASE_PHONG_BAN_PHAN_CONG.CHUA_PHAN_CONG]),
                        new AppHccMenuItem('Rà soát', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.ThamDinhHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-tham-dinh',
                            TT05_FORM_ID.CHUYEN_VIEN, [TT05_FORM_CASE_CHUYEN_VIEN_THAM_DINH.CHO_THAM_DINH,
                            TT05_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_LAI,
                            TT05_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_BO_SUNG]),
                        new AppHccMenuItem('Phó phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.LanhDaoPhongDuyetHoSo.PhoPhong', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-pho-phong-duyet',
                            TT05_FORM_ID.PHO_PHONG, [TT05_FORM_CASE_PHO_PHONG.CHO_DUYET,
                            TT05_FORM_CASE_PHO_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Trưởng phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.LanhDaoPhongDuyetHoSo.TruongPhong', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-truong-phong-duyet',
                            TT05_FORM_ID.TRUONG_PHONG, [TT05_FORM_CASE_TRUONG_PHONG.CHO_DUYET,
                            TT05_FORM_CASE_TRUONG_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Lãnh đạo cục duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT05.LanhDaoCucDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-05/ho-so-lanh-dao-duyet',
                            TT05_FORM_ID.LANH_DAO_CUC, [TT05_FORM_CASE_LANH_DAO_CUC_DUYET.CHO_DUYET]),
                    ]),
                new AppHccMenuItem('Thủ tục 06', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/lua-chon-chuc-nang', null, [],
                    [
                        new AppHccMenuItem('Đăng ký hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06.NopHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/ho-so-doanh-nghiep',
                            TT06_FORM_ID.DOANH_NGHIEP, [
                            TT06_FORM_CASE_DANG_KY_HO_SO.HO_SO_MOI,
                            TT06_FORM_CASE_DANG_KY_HO_SO.HO_SO_CHO_THANH_TOAN,
                            TT06_FORM_CASE_DANG_KY_HO_SO.HO_SO_CAN_BO_SUNG,
                            TT06_FORM_CASE_DANG_KY_HO_SO.HO_SO_BI_TRA_LAI,
                        ]),
                        new AppHccMenuItem('Phân công hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06.PhanCongHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/ho-so-phan-cong',
                            TT06_FORM_ID.PHAN_CONG, [TT06_FORM_CASE_PHONG_BAN_PHAN_CONG.CHUA_PHAN_CONG]),
                        new AppHccMenuItem('Rà soát', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06.ThamDinhHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/ho-so-tham-dinh',
                            TT06_FORM_ID.CHUYEN_VIEN, [TT06_FORM_CASE_CHUYEN_VIEN_THAM_DINH.CHO_THAM_DINH,
                            TT06_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_LAI,
                            TT06_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_BO_SUNG]),
                        new AppHccMenuItem('Lãnh đạo phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06.LanhDaoPhongDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/ho-so-lanh-dao-phong-duyet',
                            TT06_FORM_ID.LANH_DAO_PHONG, [TT06_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET,
                            TT06_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Lãnh đạo cục duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT06.LanhDaoCucDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-06/ho-so-lanh-dao-duyet',
                            TT06_FORM_ID.LANH_DAO_CUC, [TT06_FORM_CASE_LANH_DAO_CUC_DUYET.CHO_DUYET]),
                    ]),

                new AppHccMenuItem('Thủ tục 07', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/lua-chon-chuc-nang', null, [],
                    [
                        new AppHccMenuItem('Đăng ký hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07.NopHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/ho-so-doanh-nghiep',
                            TT07_FORM_ID.DOANH_NGHIEP, [
                            TT07_FORM_CASE_DANG_KY_HO_SO.HO_SO_MOI,
                            TT07_FORM_CASE_DANG_KY_HO_SO.HO_SO_CHO_THANH_TOAN,
                            TT07_FORM_CASE_DANG_KY_HO_SO.HO_SO_CAN_BO_SUNG,
                            TT07_FORM_CASE_DANG_KY_HO_SO.HO_SO_BI_TRA_LAI,
                        ]),
                        new AppHccMenuItem('Phân công hồ sơ', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07.PhanCongHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/ho-so-phan-cong',
                            TT07_FORM_ID.PHAN_CONG, [TT07_FORM_CASE_PHONG_BAN_PHAN_CONG.CHUA_PHAN_CONG]),
                        new AppHccMenuItem('Rà soát', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07.ThamDinhHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/ho-so-tham-dinh',
                            TT07_FORM_ID.CHUYEN_VIEN, [TT07_FORM_CASE_CHUYEN_VIEN_THAM_DINH.CHO_THAM_DINH,
                            TT07_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_LAI,
                            TT07_FORM_CASE_CHUYEN_VIEN_THAM_DINH.THAM_DINH_BO_SUNG]),
                        new AppHccMenuItem('Lãnh đạo phòng duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07.LanhDaoPhongDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/ho-so-lanh-dao-phong-duyet',
                            TT07_FORM_ID.LANH_DAO_PHONG, [TT07_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET,
                            TT07_FORM_CASE_LANH_DAO_PHONG.CHO_DUYET_LAI]),
                        new AppHccMenuItem('Lãnh đạo cục duyệt', 'Pages.ThuTucHanhChinh.HoSoThuTuc.TT07.LanhDaoCucDuyetHoSo', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-07/ho-so-lanh-dao-duyet',
                            TT07_FORM_ID.LANH_DAO_CUC, [TT07_FORM_CASE_LANH_DAO_CUC_DUYET.CHO_DUYET]),
                    ]),
            ]),
        ]);
    }


}
