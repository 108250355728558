export class CodeSystemConst {
    static GIOI_TINH = 'GIOI_TINH';
    static DAN_TOC = 'DAN_TOC';
    static LV_VAN_BAN = 'LV_VAN_BAN';
}

export class ComboBoxTableCode {
    static GetAllDoanhNghiepToDLL = 'GetAllDoanhNghiepToDLL';
    static GetChuKyByIdDLL = 'GetChuKyByIdDLL';
    static GetAllTaiLieuHuongDanDLL = 'GetAllTaiLieuHuongDanDLL';
    static LOAI_TAI_LIEU_HUONG_DAN = 'LOAI_TAI_LIEU_HUONG_DAN';
    static QUOC_GIA = 'QUOC_GIA';
    static GetAllUsers = 'GetAllUsers';
    static GetAllUserByPermission = 'GetAllUserByPermission';
    static GetAllPhongBanToDLL = 'GetAllPhongBanToDLL';
    static GetAllBanGiamDocToDLL = 'GetAllBanGiamDocToDLL';
    static GetAllUserPhongBanToDLL = 'GetAllUserPhongBanToDLL';
    static GetAllChucVuToDLL = 'GetAllChucVuToDLL';
    static GetAllTenantToDLL = 'GetAllTenantToDLL';
    static GetAllThuTucToDLL = 'GetAllThuTucToDLL';
    //TT04
    static GetAllLoaiSanPham = 'GetAllLoaiSanPham';
    static GetAllDangTrinhBay = 'GetAllDangTrinhBay';
    static GetAllCongTySanXuat = 'GetAllCongTySanXuat';
    static GetAllCongTyDongGoi = 'GetAllCongTyDongGoi';

    //CommonEnum
    static GIOI_TINH = 'GIOI_TINH';
    static LOAI_DONG_GOI = 'LOAI_DONG_GOI';
    static TRANG_THAI_CONG_VAN = 'TRANG_THAI_CONG_VAN';
    static HINH_THUC_THANH_TOAN = 'HINH_THUC_THANH_TOAN';
    static MA_THU_TUC = 'MA_THU_TUC';
    static TRANG_THAI_DOANH_NGHIEP_DANG_KY = 'TRANG_THAI_DOANH_NGHIEP_DANG_KY';
    static LOAI_CHU_KY = 'LOAI_CHU_KY';
}


interface Option {
    label: string;
    value: string;
    age: number;
}

export const Level_Phan_Quyen = [

]

