import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable, Subject, of } from 'rxjs';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _appNavigationService: AppNavigationService,
        private _refreshTokenService: RefreshTokenService,
    ) {
    }

    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }

        if (!this._sessionService.user) {
            let sessionObservable = new Subject<any>();

            this._refreshTokenService.tryAuthWithRefreshToken()
                .subscribe(
                    (autResult: boolean) => {
                        if (autResult) {
                            sessionObservable.next(true);
                            sessionObservable.complete();
                            location.reload();
                        } else {
                            sessionObservable.next(false);
                            sessionObservable.complete();
                            this._router.navigate(['/account/login']);
                        }
                    },
                    (error) => {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        this._router.navigate(['/account/login']);
                    },
                );
            return sessionObservable;
        }

        if (!data || !data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }
        var menu = this._appNavigationService.getMenu();

        var permissionChecker = new PermissionCheckerService;
        var arrayPermission = menu.items.filter(x => x.permissionName && permissionChecker.isGranted(x.permissionName));

        if (arrayPermission.length == 0) {
            return;
        }

        if (arrayPermission[0].items.length == 0) {
            return '/' + arrayPermission[0].route;
        }
        else {
            var array = arrayPermission[0].items.filter(x => x.permissionName && permissionChecker.isGranted(x.permissionName));
            return '/' + array[0].route;
        }
        if (this._permissionChecker.isGranted('Pages.DoanhNghiep')) {
            return '/app/admin/doanhNghiepDashboard';
        }

        return '/app/welcome';
    }
}
